// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "22.1.0",
  "buildNumber": "13913",
  "gitSha": "f4c9c1650e2e27fe0a9962faa39536f94f57e297",
  "fullVersion": "22.1.0-13913",
  "formattedVersion": "22.1.0 (13913-f4c9c1650e2e27fe0a9962faa39536f94f57e297)",
  "copyrightYear": "2022"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/22.1.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/22.1.0/${stripLeadingPrefix(suffix, '#')}`
}
    
